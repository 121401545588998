<template>
  <div>
    <div class="agGrid-page container">
      <base-header>
        <show-archived-addon />
      </base-header>
      <div class="staffs" v-if="staffs">
        <div
          v-for="staff in this.staffs"
          :key="staff._id"
          draggable="true"
          class="staff active"
          :data-id="staff._id"
          @dragstart="dragStartHandler($event, JSON.stringify(staff))"
        >
          {{ staff.name }}
        </div>
      </div>
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :pinnedTopRowDataCondition="pinnedTopRowDataCondition"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
import MonthManagerRenderer from '@/agGridV2/renderers/month-manager.renderer.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import ShowArchivedAddon from '@/agGridV2/components/header/show-archived.component.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'

export default {
  name: 'team-changes-component',
  components: {
    BaseHeader,
    ShowArchivedAddon,
    BaseAgGrid,
    // eslint-disable-next-line vue/no-unused-components
    MonthManagerRenderer
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      customHtml: null,
      pinnedTopRowDataCondition: null,
      request: null,
      staffs: []
    }
  },
  props: ['team', 'startYear', 'startMonth'],
  async mounted() {
    const [staffs] = await Promise.all([MtApi.getStaffsByActivityTag()])
    if (this.team === 'am_supervisor') {
      if (staffs['am'].length > 0) {
        this.staffs = staffs['am'].filter((s) => s.status === 'active')
      }
    } else {
      if (staffs[this.team].length > 0) {
        this.staffs = staffs[this.team].filter((s) => s.status === 'active')
      }
    }
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth()
    const startYear = Number(this.startYear)
    const startMonth = Number(this.startMonth)
    const yearColumns = []
    const months = SimpleHelper.monthsLabels
    const years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => startYear + index
    ).map(Number)
    for (const year of years) {
      for (let month = 0; month <= 11; month++) {
        if (year !== 2023) {
          if (currentYear === startYear && startMonth > month) {
            continue
          }
          if (currentMonth < month) {
            continue
          }
        }
        yearColumns.push({
          field: `${this.team}_${month + '_' + year}`,
          headerName: months[month] + ' ' + year,
          getQuickFilterText: (params) => {
            if (!params.value) {
              return ''
            }
            return params.value.name
          },
          cellRenderer: 'MonthManagerRenderer',
          cellRendererParams: {
            team: this.team
          },
          filter: false
        })
      }
    }
    this.columnDefs = [
      {
        field: 'name',
        headerName: 'Client Name (QB)',
        width: 300
      },
      {
        field: 'projects',
        headerName: 'Projects',
        cellRenderer: function (params) {
          let stringProjects = ''
          for (const project of params.value) {
            stringProjects += ` <div class="project_status project_status--${project.status}">${project.abbr_s}-${project.type}</div>`
          }
          return stringProjects
        }
      },
      ...yearColumns
    ]
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(
          this.$route.name
        )
        params.request['showArchived'] = this.$store.getters.getShowArchived(
          this.$route.name
        )
        MtApi.agGridTeamChanges(params.request).then((response) => {
          params.success({ rowData: response.rows, rowCount: response.count })
          params.api.redrawRows()
        })
      }
    }
  },
  methods: {
    dragStartHandler(event, staffId) {
      event.dataTransfer.setData('text/plain', staffId)
    }
  }
}
</script>
